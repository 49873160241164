import utilsService from '@/services/utils/utils.service'
import localstorageService from '@/services/localstorage/localstorage.service'
import { BaseService } from './base.service'

const { currentUserId, userRole, userData } = utilsService.currentUserUtils()

export class FormulaireService extends BaseService {
  // eslint-disable-next-line no-useless-constructor

  fetchFormulaires({ formType, params }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/${formType}/list`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  exportFormulaires({ formType, params }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/${formType}/export`, { params, responseType: 'blob' })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getFormulaire({ formType, id }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/${formType}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateFormulaire({ formType, id, data }) {
    // console.log(formType, id);
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/${formType}/${id}`, { ...data })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getLastFormCreatedByUser({ formType, userId }) {
    return new Promise((resolve, reject) => {
      const route = '/admins/last-form'
      this.axiosInstance
        .get(route, {
          params: {
            formType,
            userId,
          },
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  addFormulaire({ formType, data }) {
    console.log('addFormulaire ❌❌❌ ', 'formType: ', formType, 'data: ', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/${formType}/create`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getFormulairesGrandPublic(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/admins/get-all-forms/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new FormulaireService()
