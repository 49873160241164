import formulaireService from '@/services/http/formulaire.service'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async fetchFormulaires({ rootState }, data) {
            const res = await formulaireService.fetchFormulaires(data)
            return res
        },
        async exportFormulaires({ rootState }, data) {
            const res = await formulaireService.exportFormulaires(data)
            return res
        },
        async getFormulaire({ rootState }, data) {
            const res = await formulaireService.getFormulaire(data)
            return res
        },
        async updateFormulaire({ rootState }, data) {
            const res = await formulaireService.updateFormulaire(data)
            return res
        },
        async addFormulaire({ rootState }, data) {
            const res = await formulaireService.addFormulaire(data)
            return res
        },
        async getLastFormCreatedByUser({ rootState }, data) {
            const res = await formulaireService.getLastFormCreatedByUser(data)
            return res
        },
        async getFormulairesGrandPublic({ rootState }, data) {
            const res = await formulaireService.getFormulairesGrandPublic(data)
            return res
        },
    },
}